import {Route, Routes} from "react-router-dom";
import Home from "./pages/home";
import Logout from "./pages/logout";
import Signin from "./pages/signin";

function Router() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/logout" element={<Logout />} />
        </Routes>
    );
}

export default Router;